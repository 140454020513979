<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <clipPath id="clip-path">
     /&gt;
     <rect :fill="background" data-name="Rectangle 21" height="45" id="Rectangle_21" style="fill: #fff;" transform="translate(450.468 524.991)" width="45"/>
    </clipPath>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient69" x1="1" x2="0.36" y2="1">
     <stop offset="0" stop-color="#2dc100"/>
     <stop offset="0.33" stop-color="#2fb705"/>
     <stop offset="1" stop-color="#388022"/>
    </linearGradient>
   </defs>
   <g id="Wechat" transform="translate(-450 -608)">
    <g id="Discord" transform="translate(0 80)">
     <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21-2" style="fill: #fff;" transform="translate(450 528)" width="60"/>
    </g>
    <g data-name="Discord" id="Discord-2" transform="translate(-2 78)">
     <g class="cls-2" data-name="Mask Group 3" id="Mask_Group_3" transform="translate(9.532 13.009)">
      <g data-name="Layer 2" id="Layer_2" transform="translate(453 529)">
       <g data-name="28.we chat" id="_28.we_chat">
        <ellipse class="cls-3" cx="19.5" cy="18.5" id="background" rx="19.5" ry="18.5" transform="translate(0.468 -0.009)"/>
        <g id="icon" transform="translate(7.401 8.861)">
         <path d="M37.232,25.7c-6.559-3.123-14.013,2.617-10.6,7.882,1.583,2.472,5.236,4.033,9.557,2.863.866.313,1.635.824,2.467,1.2-.224-.685-.448-1.36-.693-2.032,4.472-2.98,3.494-7.9-.727-9.916ZM32,29.251a1.022,1.022,0,0,1-1.075.606.982.982,0,0,1-.881-.837.948.948,0,0,1,.661-1C31.457,27.723,32.327,28.543,32,29.251Zm4.909.09h.013a1.027,1.027,0,0,1-.739.507A1.076,1.076,0,0,1,35.3,29.6a1.433,1.433,0,0,1-.352-.706,1.1,1.1,0,0,1,.955-.923A.978.978,0,0,1,36.9,29.342Z" data-name="Path 267" id="Path_267" style="fill: #fff;" transform="translate(-16.22 -18.377)"/>
         <path d="M29.145,20.409A7.387,7.387,0,0,0,25.221,15.6h.027c-6.88-3.644-15.444,1.438-13.789,7.43a7.34,7.34,0,0,0,3.348,4.265c-.307.822-.6,1.641-.877,2.467,1-.492,2-1.007,3-1.507a11.74,11.74,0,0,0,3.756.5c-1.57-4.246,2.467-8.743,8.454-8.344Zm-6.234-2.465a1.336,1.336,0,0,1,1.171.153,1.162,1.162,0,0,1,.525.993V19.1a1.286,1.286,0,0,1-2.05.879A1.162,1.162,0,0,1,22.911,17.944Zm-4.528,1.393a1.3,1.3,0,0,1-2,.654,1.169,1.169,0,0,1,.354-2.058A1.245,1.245,0,0,1,18.384,19.337Z" data-name="Path 268" id="Path_268" style="fill: #fff;" transform="translate(-11.251 -14.373)"/>
        </g>
       </g>
      </g>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>